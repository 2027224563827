import React from 'react';
import { NumberInput, SelectInput, BooleanInput, FormDataConsumer, TranslatableInputs } from 'react-admin';
import { getAllLangElemAndSetStyle } from '../../utils/helpers';
import RichTextInput from 'ra-input-rich-text';
import {
  HEADER_LINE,
  HEADER_FONT,
  DESC_LINE,
  DESC_FONT,
  POS_CHOICES,
  RICHTEXT_TOOLBAR_PROPS,
  LANGUAGES
} from '../../utils/constants';

export const TranslationFields = () => (
  <>
    <BooleanInput source="show_header" label="Show Header" initialValue={true} />
    <BooleanInput source="show_desc" label="Show Description" initialValue={true} />
    <FormDataConsumer>
      {({ formData }) => {
        const {
          show_header = true,
          show_desc = true,
          header_line_height = HEADER_LINE,
          header_font_size = HEADER_FONT,
          desc_line_height = DESC_LINE,
          desc_font_size = DESC_FONT
        } = formData || {}
        getAllLangElemAndSetStyle('header', header_font_size, header_line_height)
        getAllLangElemAndSetStyle('description', desc_font_size, desc_line_height)
        return (
          (show_header || show_desc) &&
          <>
            {show_header && <NumberInput className="header" source="header_line_height" label="Header leading in px" initialValue={header_line_height} min={0} max={120} step={5} />}
            {show_desc && <NumberInput className="desc" source="desc_line_height" label="Description leading in px" initialValue={desc_line_height} min={0} max={120} step={5} />}
            <div>
              {show_header && <NumberInput className="header" source="header_font_size" label="Header font size in px" initialValue={header_font_size} min={0} max={100} step={1} />}
              {show_desc && <NumberInput className="desc" source="desc_font_size" label="Description font size in px" initialValue={desc_font_size} min={0} max={100} step={1} />}
            </div>
            <div>
              <SelectInput className="header" source="text_position" initialValue={'middle'} choices={POS_CHOICES} />
            </div>
            <TranslatableInputs locales={LANGUAGES}>
              {show_header && <RichTextInput source="header" toolbar={RICHTEXT_TOOLBAR_PROPS} fullWidth />}
              {show_desc && <RichTextInput source="description" toolbar={RICHTEXT_TOOLBAR_PROPS} fullWidth />}
            </TranslatableInputs>
          </>
        )
      }}
    </FormDataConsumer>
  </>
)
