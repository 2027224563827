import React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { PostFilter } from '../../common/Filters';

export const ProductList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="description.en" label="English Description" />
      <FunctionField label="Has Image" render={record => {
        if (record.images && record.images.length) {
          return '✔';
        }
        return '✖';
      }} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
