import React from 'react';
import { Create, Toolbar, SimpleForm, TextInput } from 'react-admin';
import { MediaUploader } from '../../common';

export const GalleryCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="images" accept="image/*" multiple />
      <TextInput source="description" multiline/>
    </SimpleForm>
  </Create >
);
