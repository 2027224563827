import React from 'react';
import { Edit, Toolbar, SimpleForm, TextInput } from 'react-admin';

export const SolutionEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <TextInput source="letter" fullWidth />
      <TextInput source="name" fullWidth />
    </SimpleForm>
  </Edit >
);
