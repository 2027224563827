import React, { useState, useEffect, Fragment } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  CheckboxGroupInput,
  TranslatableInputs,
  NumberInput,
  useDataProvider,
  Loading,
  Error
} from 'react-admin';
import { MediaUploader } from '../../common';
import { LANGUAGES } from '../../../utils/constants';

export const ProductEdit = props => {
  const dataProvider = useDataProvider();
  const [attributions, setAttributions] = useState();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    dataProvider.getList('product-attributes',
      {
        pagination: {
          page: 1,
          perPage: 50
        },
        sort: { field: 'id', order: 'ASC' }
      })
      .then(({ data }) => {
        setAttributions(data);
        setLoading(false);
      })
      .catch(error => {
        setError(error);
        setLoading(false);
      })
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Error />;
  if (!attributions) return null;

  return (
    <Edit {...props}>
      <SimpleForm>
        <div style={{ width: '40%', float: 'left' }}>
          <TextInput source="artno" fullWidth />
          <TextInput source="name" fullWidth />
          <ReferenceInput source="solution" reference="solutions">
            <SelectInput optionText={({ letter = '', name = '' } = {}) => {
              return `${letter} (${name})`
            }} />
          </ReferenceInput>
        </div>
        <div style={{ width: '40%', float: 'left', marginLeft: '10%' }}>
          <MediaUploader attribute="logo" label="Logo" />
          <MediaUploader attribute="images" label="Images" multiple />
        </div>
        <TranslatableInputs locales={LANGUAGES}>
          <TextInput source="description" multiline fullWidth />
        </TranslatableInputs>
        <ArrayInput source="data" label="Product data">
          <SimpleFormIterator>
            <TextInput source='name' label="Name" defaultValue="" />
            <TextInput source='value' label="Value" defaultValue="" />
          </SimpleFormIterator>
        </ArrayInput>
        <ArrayInput source="bullet">
          <SimpleFormIterator>
            <TextInput source="value" label="Text" defaultValue="" />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput source="new" />
        <ArrayInput source="technical_paragraph" label="Technical Parahraphs" >
          <SimpleFormIterator>
            <TextInput source="name" label="Title" defaultValue="" />
            <TextInput source="value" label="Description" multiline fullWidth defaultValue="" />
          </SimpleFormIterator>
        </ArrayInput>
        <MediaUploader attribute="technical_images" label="Technical Images" multiple />
        <ArrayInput source="technical_data" label="Technical Data" >
          <SimpleFormIterator>
            <TextInput source="name" label="Title" defaultValue="" />
            <TextInput source="value" label="Description" defaultValue="" />
          </SimpleFormIterator>
        </ArrayInput>
        <ReferenceInput source="range" reference="ranges">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput source="category" reference="categories">
          <SelectInput optionText="name" />
        </ReferenceInput>
        <ArrayInput source="keywords" label="Keywords" >
          <SimpleFormIterator>
            <TextInput source="value" label="Keyword" defaultValue="" />
          </SimpleFormIterator>
        </ArrayInput>
        {attributions && !!attributions.length && attributions.map(attr => {
          const { name = '', types = [] } = attr || {}
          const choices = types.map(type => {
            const { name = '' } = type
            return {
              name,
              id: name
            }
          })
          return (
            <Fragment key={name}>
              <div>
                {name.toUpperCase()}
              </div>
              <CheckboxGroupInput source={`attributions.${name}.types`} label="Types" choices={choices} />
            </Fragment>
          )
        })}
        <NumberInput source="price" />
      </SimpleForm>
    </Edit>
  )
};
