import React from 'react';
import { Create, Toolbar, SimpleForm, TextInput } from 'react-admin';
import { TranslationFields, MediaUploader } from '../../common';

export const MenuCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="background" accept="image/*" multiple />
      <TextInput source="name" fullWidth />
      <TranslationFields />
    </SimpleForm>
  </Create >
);
