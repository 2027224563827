import React from 'react';
import { Edit, Toolbar, SimpleForm, TextInput } from 'react-admin';
import { MediaUploader } from '../../common';

export const PdfEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="logo" accept="image/*" />
      <TextInput source="header" fullWidth />
      <TextInput source="footer" fullWidth />
    </SimpleForm>
  </Edit >
);
