import * as React from "react";
import { Admin, Resource } from 'react-admin';
import authProvider from './providers/authProvider';
import simpleRestProvider from './providers/simpleRestProvider';
import { ProductList, ProductCreate, ProductEdit } from './render/views/products';
import { RangeList, RangeCreate, RangeEdit } from './render/views/ranges';
import { CategoryList, CategoryCreate, CategoryEdit } from './render/views/categories';
import { MenuList, MenuCreate, MenuEdit } from './render/views/menus';
import { MainMenuList, MainMenuCreate, MainMenuEdit } from './render/views/mainmenus';
import { TeamList, TeamCreate, TeamEdit } from "./render/views/teams";
import { AttributesCreate, AttributesEdit, AttributesList } from "./render/views/product-attributes";
import { IconCreate, IconEdit, IconList } from "./render/views/icons";
import { CurrencyCreate, CurrencyEdit, CurrencyList } from "./render/views/currencies";
import { PdfCreate, PdfEdit, PdfList } from "./render/views/pdf-exports";
import { SolutionCreate, SolutionEdit, SolutionList } from "./render/views/solutions";
import { GalleryCreate, GalleryEdit, GalleryList } from "./render/views/galleries";
import { GateKeeperList } from "./render/views/gatekeeper";
import { UPLOAD_FIELDS } from './utils/constants';
const dataUrl = process.env.REACT_APP_DATA_URL;

const App = () => (
  <Admin
    dataProvider={simpleRestProvider(dataUrl, UPLOAD_FIELDS)}
    authProvider={authProvider}
  >
    <Resource name="products" list={ProductList} create={ProductCreate} edit={ProductEdit} />
    <Resource name="ranges" list={RangeList} create={RangeCreate} edit={RangeEdit} />
    <Resource name="categories" list={CategoryList} create={CategoryCreate} edit={CategoryEdit} />
    <Resource name="mainmenus" list={MainMenuList} create={MainMenuCreate} edit={MainMenuEdit} />
    <Resource name="menus" list={MenuList} create={MenuCreate} edit={MenuEdit} />
    <Resource name="teams" list={TeamList} create={TeamCreate} edit={TeamEdit} />
    <Resource name="product-attributes" list={AttributesList} create={AttributesCreate} edit={AttributesEdit} />
    <Resource name="icons" list={IconList} create={IconCreate} edit={IconEdit} />
    <Resource name="currencies" list={CurrencyList} create={CurrencyCreate} edit={CurrencyEdit} />
    <Resource name="pdf-exports" list={PdfList} create={PdfCreate} edit={PdfEdit} />
    <Resource name="solutions" list={SolutionList} create={SolutionCreate} edit={SolutionEdit} />
    <Resource name="galleries" list={GalleryList} create={GalleryCreate} edit={GalleryEdit} />
    <Resource name="gatekeepers" list={GateKeeperList} />
  </Admin>
);

export default App;
