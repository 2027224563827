import React from 'react';
import { Create, Toolbar, SimpleForm, TextInput, NumberInput } from 'react-admin';

export const CurrencyCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <TextInput source="currency" fullWidth />
      <NumberInput source="change" />
    </SimpleForm>
  </Create >
);
