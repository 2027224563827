import React, { createContext, useContext } from 'react';

const dataUrl = process.env.REACT_APP_DATA_URL;

const ApiDetailsContext = createContext({ dataUrl });

export const ApiDetailsProvider = ({ children }) => {
    return (
        <ApiDetailsContext.Provider value={{ dataUrl }}>
            { children }
        </ApiDetailsContext.Provider>
    );
}

export default function useApiDetails() {
    return useContext(ApiDetailsContext);
};
