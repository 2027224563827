import React from 'react';
import { Edit, Toolbar, SimpleForm, TextInput, TranslatableInputs } from 'react-admin';
import { MediaUploader } from '../../common';
import { LANGUAGES } from '../../../utils/constants';

export const IconEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="image" accept="image/*" />
        <TranslatableInputs locales={LANGUAGES}>
          <TextInput source="description" multiline fullWidth />
        </TranslatableInputs>
      <TextInput source="order" />
    </SimpleForm>
  </Edit >
);
