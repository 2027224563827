const STORAGE_KEY_AUTH_TOKEN = 'token';

const authProvider = {
  login: async ({ username, password }) => {
    const authUrl = process.env.REACT_APP_AUTH_URL;
    const request = new Request(authUrl, {
      method: 'POST',
      body: JSON.stringify({ identifier: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const auth = await response.json();
      localStorage.setItem(STORAGE_KEY_AUTH_TOKEN, JSON.stringify(auth));
    } catch (e) {
      throw new Error('Network error');
    }
  },
  logout: () => {
    localStorage.removeItem(STORAGE_KEY_AUTH_TOKEN);
    return Promise.resolve();
  },
  checkAuth: params => {
    return localStorage.getItem(STORAGE_KEY_AUTH_TOKEN)
      ? Promise.resolve()
      : Promise.reject();
  },
  checkError: error => Promise.reject(),
  getPermissions: permissions => Promise.resolve(),
};

export default authProvider;