import React from 'react';
import {
  Create,
  Toolbar,
  SimpleForm,
  TextInput,
  BooleanInput,
  NumberInput,
  ArrayInput,
  SimpleFormIterator
} from 'react-admin';

export const AttributesCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <TextInput source="name" fullWidth />
      <TextInput source="show_name" fullWidth />
      <BooleanInput source="select_field" />
      <NumberInput source="select_order" />
      <ArrayInput source="types">
        <SimpleFormIterator>
          <TextInput source="name" label="name" defaultValue="" />
          <TextInput source="value" label="value" defaultValue="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Create >
);
