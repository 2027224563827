import React from 'react';
import { Create, Toolbar, SimpleForm, TextInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { TranslationFields, MediaUploader } from '../../common';

export const RangeCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="background" accept="image/*" />
      <TextInput source="name" fullWidth />
      <TranslationFields />
      <ReferenceArrayInput source="categories" reference="categories">
        <AutocompleteArrayInput/>
      </ReferenceArrayInput>
      <ReferenceArrayInput source="products" reference="products">
        <AutocompleteArrayInput/>
      </ReferenceArrayInput>
    </SimpleForm>
  </Create >
);
