import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ImageField, ImageInput } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import DescriptionIcon from '@material-ui/icons/Description';
import { useField } from 'react-final-form';
import useApiDetails from '../../providers/ApiDetailsContext';
import Button from '@material-ui/core/Button';
import { getToken } from '../../utils/storageutils';

const DEFAULT_GRID_GAP = 8;
const DEFAULT_ATTRIBUTE_NAME = 'media';
const DEFAULT_FIELD_LABEL = 'Media';
const DEFAULT_ATTRIBUTE_ACCEPT = 'image/*,video/*,.pdf,.csv,.doc,.docx,.ppt,.pptx';
const DEFAULT_MEDIA_BOX_WIDTH = '155px';
const DEFAULT_MEDIA_BOX_HEIGHT = '155px';

const getMediaMetaInfo = (mediaObject) => {
  const { alternativeText } = mediaObject;
  let mediaMetaInfo;
  try {
    mediaMetaInfo = JSON.parse(alternativeText);
  } catch (error) {
    mediaMetaInfo = {};
  }
  return {
    isVisible: false,
    ...mediaMetaInfo,
  };
};

const MediaElement = props => {
  const { dataUrl } = useApiDetails();
  const { mediaData } = props;

  const useStyles = makeStyles({
    image: {
      height: 'auto',
      width: '100%',
    },
    document: {
      color: 'white',
      maxHeight: DEFAULT_MEDIA_BOX_HEIGHT,
      maxWidth: DEFAULT_MEDIA_BOX_WIDTH,
      overflowWrap: 'anywhere',
      overflowY: 'auto',
      textAlign: 'center',
    },
  });
  const classes = useStyles();

  const isImage = () => {
    return mediaData.formats || (mediaData.src && RegExp('\.(jpg|jpeg|gif|png|svg)$').test(mediaData.title));
  };

  const src = mediaData.formats
    ? `${dataUrl}${mediaData.formats.thumbnail.url}`
    : mediaData.src;

  const title = mediaData.title ? mediaData.title : `${mediaData.name}${mediaData.ext}`;

  return isImage()
    ? <img src={src} alt="" className={classes.image} />
    : <div className={classes.document}>
      <DescriptionIcon style={{ fontSize: 110 }} />
      <div>{title}</div>
    </div>;
};

const MediaBox = props => {
  const { mediaData, index, onVisibilityChange } = props;
  const { dataUrl } = useApiDetails();
  const [isVisible, setIsVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const useStyles = makeStyles({
    mediaContainer: {
      alignItems: 'normal',
      color: 'white',
      display: 'flex',
      height: DEFAULT_MEDIA_BOX_HEIGHT,
      justifyContent: 'start',
      position: 'relative',
      width: DEFAULT_MEDIA_BOX_WIDTH,
    },
    visibilityCheckbox: {
      position: 'absolute',
      right: '10px',
      top: '10px',
    },
  });
  const classes = useStyles();

  useEffect(() => {
    if (mediaData) {
      const mediaMetaInfo = getMediaMetaInfo(mediaData);
      setIsVisible(mediaMetaInfo.isVisible);
    }
  }, [mediaData, index]);

  const toggleVisibility = (changeEvent) => {
    setIsLoading(true);
    const isChecked = changeEvent.target.checked;
    onVisibilityChange(isChecked, index);

    const body = new FormData();
    body.append('fileInfo', JSON.stringify({
      name: mediaData.name,
      alternativeText: mediaData.alternativeText,
    }));

    const jwt = getToken()
    fetch(`${dataUrl}/upload?id=${mediaData.id}`, {
      method: 'POST',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      }),
      body,
    }).then(data => data.json()).then(() => {
      setTimeout(() => {
        setIsVisible(isChecked);
        setIsLoading(false);
      }, 500);
    }).catch(error => {
      console.error(error);
      setIsLoading(false);
    });
  };

  return <div className={classes.mediaContainer}>
    {isLoading && <div>Saving...</div>}

    {!isLoading && <>
      {props.onVisibilityChange && <input type="checkbox"
        className={classes.visibilityCheckbox}
        title="Is visible?"
        checked={isVisible}
        onChange={toggleVisibility} />}

      <MediaElement mediaData={mediaData} />
    </>}
  </div>;
};

export const MediaUploader = props => {
  const [media, setMedia] = useState([]);
  const { dataUrl } = useApiDetails();

  const {
    input: { onChange, value: mediaInRecord },
  } = useField(props.attribute);

  const changeVisibility = (mediaObject, isVisible) => {
    mediaObject.alternativeText = JSON.stringify({
      ...getMediaMetaInfo(mediaObject),
      isVisible,
    });
  };

  const onVisibilityChange = (isVisible, index) => {
    if (mediaInRecord) {
      changeVisibility(
        (Array.isArray(mediaInRecord) ? mediaInRecord : [mediaInRecord])[index],
        isVisible
      );
    }
  };

  useEffect(() => {
    if (mediaInRecord) {
      setMedia(
        (Array.isArray(mediaInRecord) ? mediaInRecord : [mediaInRecord])
          .filter(mediaData => !mediaData.rawFile)
          .map((mediaData, index) => ({
            id: `item-${index}`,
            content: <MediaBox mediaData={mediaData}
              index={index}
              onVisibilityChange={props.withVisibilityFeature ? onVisibilityChange : null} />,
          }))
      );
    }
  }, [mediaInRecord]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const gridGap = DEFAULT_GRID_GAP;

  const getItemStyle = (isDragging, draggableStyle) => ({
    overflow: 'hidden',
    position: 'relative',
    userSelect: 'none',
    padding: gridGap * 2,
    margin: `${gridGap}px ${gridGap}px 0 0`,
    background: isDragging ? 'lightblue' : 'transparent',
    ...draggableStyle,
  });

  const getListStyle = () => ({
    background: '#f5f5f5',
    display: 'flex',
    marginBottom: '25px',
    padding: gridGap,
    flexFlow: 'wrap',
  });

  const deleteImage = (index) => {
    const jwt = getToken()
    const mediaId = media[index].content.props.mediaData.id;
    fetch(`${dataUrl}/upload/files/${mediaId}`, {
      method: 'DELETE',
      headers: new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      })
    }).then(() => {
      setMedia(media.filter((element, indexInFilter) => !indexInFilter === index));
    });

  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    if (props.multiple) {
      setMedia(reorder(
        media,
        result.source.index,
        result.destination.index
      ));

      onChange(reorder(
        mediaInRecord,
        result.source.index,
        result.destination.index
      ));
    }
  };

  return (
    <>
      <ImageInput
        source={props.attribute}
        label={props.label}
        accept="image/*,video/*,.pdf,.csv,.doc,.docx,.ppt,.pptx"
        multiple={props.multiple}
      >
        <ImageField source="src" title="title" />
      </ImageInput>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="horizontal">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              style={getListStyle()}
              {...provided.droppableProps}
            >
              {media.map((item, index) => (
                <Draggable key={item.id} draggableId={item.id} index={index}>
                  {(provided, snapshot) => {
                    return (
                      <>
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                        >
                          <Button
                            style={{
                              zIndex: 1000,
                              position: 'absolute'
                            }}
                            size="small"
                            variant="contained"
                            color="secondary"
                            onClick={() => deleteImage(index)}
                          >
                            Delete
                          </Button>
                          {item.content}
                        </div>
                      </>);
                  }}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

MediaUploader.propTypes = {
  withVisibilityFeature: PropTypes.bool,
  multiple: PropTypes.bool,
  attribute: PropTypes.string,
  label: PropTypes.string,
  accept: PropTypes.string,
};

MediaUploader.defaultProps = {
  withVisibilityFeature: false,
  multiple: false,
  attribute: DEFAULT_ATTRIBUTE_NAME,
  label: DEFAULT_FIELD_LABEL,
  accept: DEFAULT_ATTRIBUTE_ACCEPT,
};
