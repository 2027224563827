import React from 'react';
import { List, Datagrid, TextField, DateField, NumberField } from 'react-admin';
import { PostFilter } from '../../common/Filters';

export const AttributesList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="show_name" />
      <NumberField source="select_order" />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
