import React from 'react';
import { Edit, Toolbar, SimpleForm, TextInput } from 'react-admin';
import { MediaUploader } from '../../common';

export const TeamEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="image" accept="image/*" />
      <TextInput source="name" fullWidth />
      <TextInput source="title" fullWidth />
      <TextInput source="division" fullWidth />
      <TextInput source="phone" fullWidth />
      <TextInput source="email" type="email" fullWidth />
      <TextInput source="address.company" label="Company Name" fullWidth />
      <TextInput source="address.street" label="Address" fullWidth />
      <TextInput source="address.city" label="City" fullWidth />
    </SimpleForm>
  </Edit >
);
