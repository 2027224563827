import React from 'react';
import { Create, Toolbar, SimpleForm, TextInput, TranslatableInputs } from 'react-admin';
import { MediaUploader } from '../../common';
import { LANGUAGES } from '../../../utils/constants';

export const IconCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="image" accept="image/*" />
        <TranslatableInputs locales={LANGUAGES}>
          <TextInput source="description" multiline fullWidth />
        </TranslatableInputs>
      <TextInput source="order" />
    </SimpleForm>
  </Create >
);
