import React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { PostFilter } from '../../common/Filters';

export const CategoryList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <TextField source="header.en" label="English header" />
      <TextField source="description.en" label="English Description" />
      <FunctionField label="Has Background" render={record => {
        return !!record.background.length ? '✔' : '✖'
      }} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
