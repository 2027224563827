import React from 'react';
import { Edit, Toolbar, SimpleForm, TextInput, NumberInput } from 'react-admin';

export const CurrencyEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <TextInput source="currency" fullWidth />
      <NumberInput source="change" />
    </SimpleForm>
  </Edit >
);
