import React from 'react';
import { Create, Toolbar, SimpleForm, TextInput } from 'react-admin';
import { MediaUploader } from '../../common';

export const PdfCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="logo" accept="image/*" />
      <TextInput source="header" fullWidth />
      <TextInput source="footer" fullWidth />
    </SimpleForm>
  </Create >
);
