import React from 'react';
import { Create, Toolbar, SimpleForm, TextInput, ReferenceInput, SelectInput, ReferenceArrayInput, AutocompleteArrayInput } from 'react-admin';
import { TranslationFields, MediaUploader } from '../../common';

export const CategoryCreate = props => (
  <Create {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="background" accept="image/*" />
      <TextInput source="name" fullWidth />
      <TranslationFields />
      <ReferenceInput source="range" reference="ranges">
        <SelectInput optionText="name" />
      </ReferenceInput>
      <ReferenceArrayInput source="products" reference="products">
        <AutocompleteArrayInput/>
      </ReferenceArrayInput>
    </SimpleForm>
  </Create >
);
