import React from 'react';
import { useEffect } from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { getToken } from '../../../utils/storageutils';

const getClickPath = (record) => {
  const { type = 'products' } = record || {};

  const entityRegExp = new RegExp(/[^ ()]*/);

  let entity = type.match(entityRegExp)[0];

  return `/${entity}/${JSON.parse(record.description_2).product_id}`;
};

export const GateKeeperList = (props) => {
  useEffect(() => {
    const flush = async () => {
      const dataUrl = process.env.REACT_APP_DATA_URL;
      const jwt = getToken()
      const method = 'GET';
      const headers = new Headers({
        Accept: 'application/json',
        Authorization: `Bearer ${jwt}`,
      });
      const url = `${dataUrl}/gatekeepers/flush`;
      await fetch(url, { method, headers });
    };
    flush()
  }, [])

  return (
    <List {...props}>
      <Datagrid rowClick={(id, basepath, record) => getClickPath(record)}>
        <TextField source='description_1' label="description" />
        <TextField label='Art.No.' source='artno' />
        <FunctionField
          label='Problems'
          render={(record) => {
            console.log('record', record)
            const parsedDescription2 = JSON.parse(record.description_2);
            let description2 = 'Missing ';

            Object.keys(parsedDescription2).forEach((key) => {
              if (key !== 'product_id') {
                description2 = `${description2}${key}, `;
              }
            });
            return description2;
          }}
        />
        <DateField source='createdAt' />
      </Datagrid>
    </List>
  );
}
