import React from 'react';
import { Edit, Toolbar, SimpleForm, TextInput } from 'react-admin';
import { TranslationFields, MediaUploader } from '../../common';

export const MenuEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="background" accept="image/*" multiple />
      <TextInput source="name" fullWidth />
      <TranslationFields />
    </SimpleForm>
  </Edit >
);
