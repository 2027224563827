export const LANGUAGES = ['en', 'dk']
export const UPLOAD_FIELDS = ['background', 'logo', 'image', 'images', 'technical_images']
export const MAIN_SELECTOR = '.ra-input.ra-input-undefined #translatable-content-'
export const SUB_SELECTOR = ' .ra-input.ra-input-'
export const QL_SELECTOR = ' .ql-editor'
export const HEADER_FONT = 40
export const HEADER_LINE = 40
export const DESC_FONT = 20
export const DESC_LINE = 20

export const STORAGE_KEY_AUTH_TOKEN = 'token'

export const RICHTEXT_TOOLBAR_PROPS = [
  ['bold'],
  [{ 'color': [] }],
  ['clean'],
  [{ 'list': 'ordered' }, { 'list': 'bullet' }],
]

export const POS_CHOICES = [
  { name: 'Top', id: 'top' },
  { name: 'Middle', id: 'middle' },
  { name: 'Bottom', id: 'bottom' }
]
