import React from 'react';
import { Edit, Toolbar, SimpleForm, TextInput } from 'react-admin';
import { MediaUploader } from '../../common';

export const GalleryEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <MediaUploader attribute="images" accept="image/*" multiple />
      <TextInput source="description" multiline/>
    </SimpleForm>
  </Edit >
);
