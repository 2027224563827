import React from 'react';
import { List, Datagrid, TextField, DateField, FunctionField } from 'react-admin';
import { PostFilter } from '../../common/Filters';

export const MainMenuList = props => (
  <List {...props} filters={<PostFilter />}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <FunctionField label="Has Image" render={record => {
        return !!record.image.length ? '✔' : '✖'
      }} />
      <DateField source="createdAt" />
      <DateField source="updatedAt" />
    </Datagrid>
  </List>
);
