import { MAIN_SELECTOR, SUB_SELECTOR, QL_SELECTOR, LANGUAGES } from './constants';

export const setElemStyle = (elem, fontSize, lineHeight) => {
  elem.style.fontSize = `${fontSize}px`
  elem.style.padding = `${fontSize - (lineHeight / 2)}px 12px`
  elem.style.lineHeight = `${lineHeight / 2}px`
}

export const makeQuerySelector = (type, lang) => {
  return `${MAIN_SELECTOR}${lang}${SUB_SELECTOR}${type}\\.${lang}${QL_SELECTOR}`
}

export const getAllLangElemAndSetStyle = (type, fontSize, lineHeight) => {
  LANGUAGES.forEach(lang => {
    const elem = document.querySelector(makeQuerySelector(type, lang))
    if (elem) setElemStyle(elem, fontSize, lineHeight)
  })
}
