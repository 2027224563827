import React from 'react';
import { Edit, Toolbar, SimpleForm, TextInput, TranslatableInputs } from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { MediaUploader } from '../../common';
import { RICHTEXT_TOOLBAR_PROPS, LANGUAGES } from '../../../utils/constants';

export const MainMenuEdit = props => (
  <Edit {...props}>
    <SimpleForm toolbar={<Toolbar alwaysEnableSaveButton />}>
      <TextInput source="name" fullWidth />
      <MediaUploader attribute="image" multiple />
      <TranslatableInputs locales={LANGUAGES}>
        <RichTextInput source="header" toolbar={RICHTEXT_TOOLBAR_PROPS} fullWidth />
        <RichTextInput source="description" toolbar={RICHTEXT_TOOLBAR_PROPS} fullWidth />
      </TranslatableInputs>
    </SimpleForm>
  </Edit >
);
